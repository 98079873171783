import React, { Component } from "react";

import { About } from "./About/About";
import { Trail } from "./Trail/Trail";
import { AboutVideo } from "./AboutVideo/AboutVideo";
import { Collections } from "./Collections/Collections";
import { FeaturedStars } from "./FeaturedStars/FeaturedStars";
import { Footer } from "./Footer/Footer";
import { JoinDiscord } from "./JoinDiscord/JoinDiscord";
import Header from "./Header/Header";
import { Pager } from "./Pager/Pager";
import styles from "./styles.module.scss";

import api from "../api/api";
import Web3 from "web3";
import config from "../config/config";

import hashes from "../config/hashes.json";

var app = null;

class App extends Component {
    state = {
        name: "",
        provider: null,
        walletConnected: false,
        currentNetwork: null,
        userAddress: "",
        connected: false,
        wrongNetwork: false,
        nftAddress: "",
        saleEnabled: false,
        tokenPrice: 0,
        tokensPerETH: 0,
        totalSupply: 0,
        tokensLeft: 0,
        maxTokens: 0,
        loadingData: true,
        pendingCollection: [],
        pendingCollectionLoaded: 9,
        pendingCollectionMore: true,
        boughtCollection: [],
        boughtCollectionLoaded: 0,
        boughtCollectionMore: false,
    };

    initAccount = async () => {
        const web3 = this.state.provider;
        if (!web3) {
            console.log("!Web3");
            return;
        }

        const currentNetwork = await web3.eth.net.getNetworkType();
        this.setState({ currentNetwork });

        const addressList = await web3.eth.getAccounts();
        if (addressList.length) {
            const userAddress = addressList[0];
            this.setState({ userAddress });
            this.setState({ connected: true });
        } else {
            this.setState({ userAddress: "" });
            this.setState({ connected: false });
        }

        const nftContract = api.nftContract(web3);

        try {
            const nftAddress = await nftContract.options.address;
            this.setState({ nftAddress });
console.log("2 --->", nftAddress)

            const currentNetwork = await web3.eth.net.getNetworkType();
            this.setState({ currentNetwork });
console.log("3 --->", currentNetwork, api.ethNetwork())

            if (!api.ethNetwork().startsWith(currentNetwork)) {
                this.setState({
                    wrongNetwork: true,
                });
                return;
            }

            const addressList = await web3.eth.getAccounts();
            const userAddress = addressList[0];
            this.setState({ userAddress });
console.log("4 --->", userAddress)

            this.setState({ connected: true });

            const saleEnabled = await nftContract.methods.isSaleActive().call();
            this.setState({ saleEnabled });

            // console.log("Connecting NFT...")
            const contractETH = 1;
            const tokenPriceWei = await nftContract.methods.getPrice().call();
            const tokenPrice = (
                web3.utils.fromWei(tokenPriceWei, "ether") - 0
            ).toFixed(3);
            this.setState({ tokenPrice });
            const tokensPerETH = (contractETH / tokenPrice).toFixed(2);
            this.setState({ tokensPerETH });
            const totalSupply = await nftContract.methods.totalSupply().call();
            const maxTokens = await nftContract.methods.MAX_TOKENS().call();
            let tokensLeft = maxTokens - totalSupply;
            if (tokensLeft < 0) {
                tokensLeft = 0;
            }
            this.setState({ totalSupply, maxTokens, tokensLeft });
            // console.log("Connected NFT")

            this.setState({ loadingData: false });

            this.setState({ boughtCollection: this.buildSold() });
            this.setState({ pendingCollection: this.buildPending() });
        } catch (e) {
            console.log(e);
        }
        // console.log("State", this.state)
    };

    async componentDidMount() {
        app = this;
        const uplink = await api.web3Uplink();
        const provider = uplink.web3;
        const walletConnected = uplink.connected;
        this.setState({ provider, walletConnected });

        this.bindProviderEvents(provider);

        await this.initAccount();
    }

    bindProviderEvents(provider) {
        provider.on &&
            provider.on("accountsChanged", async (accounts) => {
                // console.log("accountsChanged");
                await this.initAccount();
            });
    }

    async connectWeb3Modal() {
        const web3Modal = api.web3Modal;
        const provider = await web3Modal.connect();
        const web3 = new Web3(provider);
        console.log("APP --->", app);
        app.setState({ provider: web3, walletConnected: true });
        app.bindProviderEvents(provider);
        await app.initAccount();
        console.log("APP --->", app);
    }

    buildSold(len = 6) {
        let total = 0;
        let data = [];
        for (let i = parseInt(this.state.totalSupply) - 1; i >= 0; i--) {
            if (total++ >= len) {
                break;
            }
            let name = "";
            if (i === 0) {
                name = "Dorian Star Original";
            } else {
                name = "Dorian Star " + i;
            }
            data.push({
                img: "https://v1.starsnailsnft.com/" + i + ".png",
                name: name,
                // text: hashes[""+i].substr(0, 16) + "...",
                text: hashes["" + i],
                price: "" + this.state.tokenPrice + " ETH",
                // https://opensea.io/assets/0x96ed81c7f4406eff359e27bff6325dc3c9e042bd/677
                link: {
                    url: config.openSeaUrl + config.nftAddress + "/" + i,
                    text: "Check OpenSea",
                },
            });
        }
        return data;
    }

    buildPending() {
        let total = 0;
        let data = [];
        for (
            let i = parseInt(this.state.totalSupply);
            i < this.state.maxTokens;
            i++
        ) {
            if (total++ >= this.state.pendingCollectionLoaded) {
                break;
            }
            data.push({
                img: "https://v1.starsnailsnft.com/" + i + ".png",
                name: "Dorian Star " + i,
                //text: hashes[""+i].substr(0, 16) + "...",
                text: hashes["" + i],
                price: "" + this.state.tokenPrice + " ETH",
                buy: true,
            });
        }
        this.setState({
            pendingCollectionMore:
                parseInt(this.state.totalSupply) + total < this.state.maxTokens,
        });
        return data;
    }

    buildPendingCollectionMore() {
        // this.setState({pendingCollectionLoaded: this.state.pendingCollectionLoaded + 3})
    }

    render() {
        return (
            <div className={styles.app}>
                <Pager>
                    <Header
                        showPager
                        name="header"
                        wrongNetwork={this.state.wrongNetwork}
                        connected={this.state.connected}
                        walletConnected={this.state.walletConnected}
                        userAddress={this.state.userAddress}
                        connectWeb3Modal={this.connectWeb3Modal}
                        loadingData={this.state.loadingData}
                        tokensLeft={this.state.tokensLeft}
                        provider={this.state.provider}
                        tokenPrice={this.state.tokenPrice}
                    />
                    <AboutVideo showPager name="aboutVideo" />
                    <JoinDiscord name="joinDiscord" />
                    <About showPager name="about" />
                    <Trail showPager name="trail" />
                    <JoinDiscord name="joinDiscord" />
                    <FeaturedStars showPager name="featuredStars" />
                    <Collections
                        showPager
                        name="availableNFTs"
                        title="Available NFTs"
                        data={this.state.pendingCollection}
                        subtitle="Meet our star, Dorian the Snailman"
                        text="Dorian is one of the bravest colonizers in the snail dynasty. Here are his deets."
                        link={{
                            url: config.openSeaCollection,
                            text: "Open collection on Sea",
                        }}
                        moreData={this.state.pendingCollectionMore}
                        moreLoad={this.buildPendingCollectionMore}
                    />
                    <Collections
                        showPager
                        data={this.state.boughtCollection}
                        name="collectionsSnails"
                        title="Launched Snails"
                        subtitle="Meet our star, Dorian the Snailman"
                        text="Dorian is one of the bravest colonizers in the snail dynasty. Here are his deets."
                        link={{
                            url: config.openSeaCollection,
                            text: "Open collection on Sea",
                        }}
                        moreData={this.state.boughtCollectionMore}
                        moreLoad={this.buildBoughtCollectionMore}
                    />
                    <JoinDiscord name="joinDiscord" />
                    <Footer name="footer" />
                </Pager>
            </div>
        );
    }
}

export default App;
