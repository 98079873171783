import development from "./config--development.js";
import production from "./config--production.js";
const env = process.env.REACT_APP_ENV || 'development';

const config = {
  development,
  production
};

export default config[env];
