import React from "react";

export const Icon = ({ name, size, color, className }) => {
    return (
        <i
            style={{ fontSize: size, color: color }}
            className={`${name} ${className}`}
        ></i>
    );
};
