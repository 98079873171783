import styles from "./styles.module.scss";
import { Container } from "../Container/Container";
import { Button } from "semantic-ui-react";

export const Collections = ({ data, title, subtitle, text, link, name, moreData, moreLoad }) => {

    const renderCards = () =>
        data.map((item, key) => (
            <div key={key} className={styles.collections__list__itemContainer}>
                <div className={styles.collections__list__itemBg} />
                <div className={styles.collections__list__item}>
                    <img
                        className={styles.collections__list__item__img}
                        src={item.img}
                        alt="snail"
                    />
                    <div className={styles.collections__list__item__content}>
                        <h3 className={styles.collections__list__item__name}>
                            “{item.name}”
                        </h3>
                        <p className={styles.collections__list__item__text}>
                            {item.text}
                        </p>
                        <div className={styles.collections__wrapper}>
                            <p
                                className={
                                    styles.collections__list__item__price
                                }
                            >
                                {item.price}
                            </p>
                            {item.buy && (
                                <button
                                    onClick={scrollToBuy}
                                    className={styles.collections__link}
                                >
                                    Buy NFT
                                </button>
                            )}
                            {item.link && (
                                <a
                                    href={item.link.url}
                                    className={styles.collections__link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {item.link.text}
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        ));

    const scrollToBuy = () => {
        document.getElementById("purchase").scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <div className={styles.collections} name={name}>
            <Container>
                <h2 className={styles.collections__title}>{title}</h2>
                {subtitle && (
                    <h3 className={styles.collections__subtitle}>{subtitle}</h3>
                )}
                {text || link ? (
                    <div className={styles.collections__wrapper}>
                        {text && (
                            <p className={styles.collections__text}>{text}</p>
                        )}
                        {link?.url && (
                            <a
                                href={link.url}
                                className={styles.collections__linkTop}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {link.text}
                            </a>
                        )}
                    </div>
                ) : (
                    ""
                )}
                <div className={styles.collections__list}>{renderCards()}</div>
                {moreData && (
                    <div className={styles.collections__more}>
                        <Button
                            className={styles.collections__more__button}
                            primary
                            //loading={this.state.loadingButton}
                            onClick={moreLoad}
                        >
                            <Button.Content visible>Load More</Button.Content>
                        </Button>
                    </div>
                )}
            </Container>
        </div>
    );
};
