import styles from "./styles.module.scss";
import { Container } from "../Container/Container";
import buttonDiscord from "../../assets/images/joinDiscord.png";

export const JoinDiscord = ({ name }) => {
    return (
        <div className={styles.join_dis}>
            <Container>
                <div className={styles.join_dis__content}>
                    <div className={styles.join_dis__content__item}>
                        <a className={styles.join_dis__link} target="_blank" rel="noreferrer" href="https://discord.gg/nNcRqVFeeP">
                            <img
                                className={styles.join_dis__img}
                                src={buttonDiscord}
                                alt="Join Discord"
                            />
                        </a>
                    </div>
                </div>
            </Container>
        </div>
    );
};
