import React, { Component } from "react";

import styles from "./styles.module.scss";
import snailsRainbow from "../../assets/images/snailsRainbow.png";
import snailsRainbowMobile from "../../assets/images/snailsRainbowMobile.png";
import redStars from "../../assets/images/redStars.png";
import greenStars from "../../assets/images/greenStars.png";
import headerLogo from "../../assets/images/headerLogo.png";
import { Icon } from "../Icon/Icon";
import { ICONS } from "../../Constants/iconsBackground";
import PurchaseTokens from "./PurchaseTokens";
import discordImage from "../../assets/images/iconDiscordHeader.png";

class Header2 extends Component {
    render() {
        const renderStar = () => {
            return ICONS.map((icon, key) => (
                <p className={styles.header__star} key={key}>
                    <Icon
                        className={`${styles.header__star__icon} ${
                            icon === "icon-bgStar6"
                                ? styles.header__star__icon__small
                                : ""
                        }`}
                        name={icon}
                    />
                </p>
            ));
        };
        const renderStarsBg = () => {
            let arr = [];
            for (let i = 0; i < 9; i++) {
                arr.push(<>{renderStar()}</>);
            }
            return arr;
        };
        return (
            <div className={styles.header} name={this.props.name}>
                <div className={styles.header__stars}>{renderStarsBg()}</div>
                <div className={styles.header__menu}>
                    <ul className={styles.header__menu__list}>
                        <li className={styles.header__menu__item}>
                            <a href="#aboutUs">About us</a>
                        </li>
                        <li className={styles.header__menu__item}>
                            <a href="#joinSnails">Join Snails</a>
                        </li>
                        <li className={styles.header__menu__item}>
                            <a href="#roadmap">Roadmap</a>
                        </li>
                        <li className={styles.header__menu__item}>
                            <a href="#featuredStars">Collections</a>
                        </li>
                        <li className={styles.header__menu__item}>
                            <a href="#featuredStars">NFTs</a>
                        </li>
                        <li className={styles.header__menu__item}>
                            <a
                                height="50px"
                                href="https://discord.gg/nNcRqVFeeP"
                                class={styles.header__menu__discord}
                            >
                                <img
                                    src={discordImage}
                                    alt="Join our Discord"
                                />
                            </a>
                        </li>
                    </ul>
                </div>

                <div className={styles.header__content}>
                    <img
                        id="purchase"
                        className={styles.header__content__logo}
                        src={headerLogo}
                        alt="logo"
                    />
                    <h1 className={styles.header__content__title}>
                        Star Snails
                    </h1>
                    {/* <p className={styles.header__content__text}>
                        We are StarSnails and we are infinite. We have been 
                        designed to push the limits of time and space. Once, 
                        long ago, our ancestors attempted to breach the galaxy 
                        in order to expand into the wider universe. We succeeded 
                        and now we are everywhere!
                    </p> */}
                    <p className={styles.header__content__text}>
                        StarSnails is your secret ticket to the community of
                        young NFT and Metaverse creators. Our goal is to teach
                        teens to work with the new interconnected world of the
                        Metaverse, NFTs and blockchain.
                    </p>
                    <p className={styles.header__content__text}>
                        StarSnails is a project by 14-year-old{" "}
                        <a
                            className={styles.header__content__profile}
                            target="_blank"
                            rel="noreferrer"
                            href="https://opensea.io/R3PZZ"
                        >
                            Kuzma Shpak
                        </a>
                        . He is an eighth grader and built these NFTs by hand.
                        His father (
                        <a
                            className={styles.header__content__profile}
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.linkedin.com/in/vshpak/"
                        >
                            that's me!
                        </a>
                        ) has worked in blockchain for years and together they
                        want to make a safe place for teens to create artwork
                        for the Metaverse
                    </p>

                    {this.props.wrongNetwork && (
                        <div className={styles.header__content__warning}>
                            Please change network in your wallet before
                            continue!
                        </div>
                    )}
                    {!this.props.connected && (
                        <div className={styles.header__content__warning}>
                            Establishing connection to blockchain uplink...
                        </div>
                    )}
                    {!this.props.wrongNetwork && this.props.connected && (
                        <div className={styles.header__content__links}>
                            <a id="purchase" />
                            {!this.props.walletConnected ||
                            !this.props.userAddress ? (
                                <div
                                    className={
                                        styles.header__content__links__rows
                                    }
                                >
                                    <button
                                        className={styles.header__content__link}
                                        onClick={this.props.connectWeb3Modal}
                                    >
                                        Connect Wallet to Buy NFT
                                    </button>
                                    <div
                                        className={
                                            styles.header__content__salestats
                                        }
                                    >
                                        {this.props.loadingData ? (
                                            <p>
                                                <span>.. loading stats ..</span>
                                            </p>
                                        ) : (
                                            <p>
                                                <span>
                                                    NFTs left:{" "}
                                                    <strong>
                                                        {this.props.tokensLeft}
                                                    </strong>
                                                    ,
                                                </span>
                                                <span>
                                                    NFT price is{" "}
                                                    <strong>
                                                        {this.props.tokenPrice}
                                                    </strong>{" "}
                                                    ETH
                                                </span>
                                            </p>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <PurchaseTokens
                                        provider={this.props.provider}
                                        tokenPrice={this.props.tokenPrice}
                                        userAddress={this.props.userAddress}
                                        maxAmount={100}
                                    />
                                    <div
                                        className={
                                            styles.header__content__salestats
                                        }
                                    >
                                        {this.props.loadingData ? (
                                            <p>
                                                <span>.. loading stats ..</span>
                                            </p>
                                        ) : (
                                            <p>
                                                <span>
                                                    NFTs left:{" "}
                                                    <strong>
                                                        {this.props.tokensLeft}
                                                    </strong>
                                                    ,{" "}
                                                </span>
                                                <span>
                                                    NFT price is{" "}
                                                    <strong>
                                                        {this.props.tokenPrice}
                                                    </strong>{" "}
                                                    ETH
                                                </span>
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.header__snails}>
                    <img
                        className={styles.header__snails__desktop}
                        src={snailsRainbow}
                        alt="snails"
                    />
                    <img
                        className={styles.header__snails__mobile}
                        src={snailsRainbowMobile}
                        alt="snails"
                    />
                </div>
                <div className={styles.header__starsImage}>
                    <img
                        className={styles.header__starsImage__redstars}
                        src={redStars}
                        alt="red stars"
                    />
                    <img
                        className={styles.header__starsImage__greenstars}
                        src={greenStars}
                        alt="green stars"
                    />
                </div>
            </div>
        );
    }
}

export default Header2;
