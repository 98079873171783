import { useRef, useState } from "react";
import styles from "./styles.module.scss";
import Hero1Img from "../../assets/images/Hero1.png";
import Hero0Img from "../../assets/images/Hero0.png";
import Slider from "react-slick";
import { Container } from "../Container/Container";
import { Icon } from "../Icon/Icon";
import { ICONS } from "../../Constants/icons";

const data = [
    { img: Hero1Img, title: "Dorian the Snailman" },
    { img: Hero0Img, title: "Elis the Snailman" },
    { img: Hero0Img, title: "Mathew the Snailman" },
    { img: Hero0Img, title: "Travis the Snailman" },
];

export const FeaturedStars = ({ name }) => {
    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        focusOnSelect: true,
        beforeChange: (_, next) => setCurrentSlide(next),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    variableWidth: false,
                },
            },
        ],
    };

    const renderCard = () =>
        data.map((item, i) => (
            <div key={i} className={styles.sliderItem}>
                <div
                    className={`${styles.sliderItem__card} ${
                        currentSlide === i
                            ? styles.sliderItem__card__current
                            : ""
                    }`}
                >
                    <img
                        className={styles.sliderItem__card__img}
                        src={item.img}
                        alt=""
                    />
                    <h4 className={styles.sliderItem__card__title}>
                        {item.title}
                    </h4>
                    <div className={styles.sliderItem__card__social}>
                        {/* <Icon name={ICONS.twitter} />
                        <Icon name={ICONS.telegram} /> */}
                    </div>
                </div>
            </div>
        ));

    const renderDots = () =>
        data.map((_, i) => (
            <button
                className={`${styles.controls__dot} ${
                    currentSlide === i ? styles.controls__dot__active : ""
                }`}
                key={i}
                onClick={() => sliderRef.current.slickGoTo(i)}
            >
                {i + 1}
            </button>
        ));

    return (
        <section className={styles.featuredStars} name={name} id="featuredStars">
            <Container>
                <h2 className={styles.featuredStars__title}>Featured Explorers</h2>
                <p className={styles.featuredStars__text}>
                    These heroes will surf intergalactic space and colonize unique planets to grow their population and explore the universe!
                </p>
                <div className={styles.featuredStars__slider}>
                    <Slider ref={sliderRef} {...settings}>
                        {renderCard()}
                    </Slider>
                </div>

                <div className={styles.controls}>
                    <button
                        className={`${styles.controls__arrow} ${styles.controls__arrow__left}`}
                        onClick={() => sliderRef.current.slickPrev()}
                    >
                        <Icon name={ICONS.arrowSlider} />
                    </button>
                    {renderDots()}
                    <button
                        className={styles.controls__arrow}
                        onClick={() => sliderRef.current.slickNext()}
                    >
                        <Icon name={ICONS.arrowSlider} />
                    </button>
                </div>
            </Container>
        </section>
    );
};
