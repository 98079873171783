import React, { Component } from "react";
import { Form, Button } from "semantic-ui-react";
import api from "../../api/api";
import styles from "./styles.module.scss";
import { getUtmUserData } from "../GtmScript/utm";

class PurchaseTokens extends Component {
    state = {
        value: "1",
        errorMessage: "",
        processingMessage: "",
        loadingButton: false,
    };

    inputCheck = (object) => {
        const error = document.getElementById("error-nft-input");

        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
        else if (Number(object.target.value) > this.props.maxAmount) {
            object.target.value = '';
            error.textContent = "The maximum purchase is limited to " + this.props.maxAmount + " NFTs";
        }
        else {
            error.textContent = "";
        }
    };

    saveBuyStats = (buyerAddress, state, err) => {
        let data = getUtmUserData();
        data.address = buyerAddress
        data.state = state
        if (err) {
            data.err = err
        }

        fetch('/stats.json?q=' + JSON.stringify(data), {
            method: 'GET', // или 'PUT'
            headers: {
            'Content-Type': 'application/json'
            }
        });
    }

    onSubmit = async (event) => {
        const web3 = this.props.provider
        const error = document.getElementById("error-nft-input");

        if (this.state.value === '' || Number(this.state.value) === 0 || Number(this.state.value) < 1) {
            error.textContent = "Minimum tokens is 1";
        }
        else {
            event.preventDefault();
            this.setState({ loadingButton: true, errorMessage: "", processingMessage: "" });

            try {
                // Add info through datalayer
                window.dataLayer.push({
                    event: "formSubmitVA",
                    category: "FormSubmit",
                    action: "purchase",
                    value: this.props.userAddress,
                });

                this.saveBuyStats(this.props.userAddress, "prepare")
    
                const value = this.state.value
                const valueSent = web3.utils.toWei("" + (this.state.value * this.props.tokenPrice), "ether");
                this.setState({ processingMessage: "Please wait for confirmation..." });

                let contract = api.nftContract(this.props.provider) 
                await contract.methods.mint(value).send({
                    from: this.props.userAddress,
                    //gas: api.defaultGas(),
                    value: valueSent,
                });
                // await contract.methods.withdraw().send({
                //     from: this.props.userAddress,
                // });
                this.setState({
                    processingMessage: "Transaction successful. Please check your NFTs.",
                });
                this.saveBuyStats(this.props.userAddress, "success")
            } catch (err) {
                this.setState({ processingMessage: false });
                this.setState({ errorMessage: err.message });
                this.saveBuyStats(this.props.userAddress, "error", err.message)
            }
            this.setState({ loadingButton: false });
            // await this.props.initAccount();
        }
    };

    render() {
        return (
            <div className={styles.header__purchase}>
                <Form onSubmit={this.onSubmit} error={!!this.state.errorMessage} >
                    <div className={styles.header__purchase__row}>
                        <input
                            id="nft-input"
                            className={styles.header__purchase__input} 
                            placeholder="1"
                            value={this.state.value}
                            onChange={(event) => this.setState({value: event.target.value}) }
                            onInput={this.inputCheck}
                            step={1}
                            type="number"
                            autoComplete="off"
                            autoCorrect="off"
                            maxLength={3}
                            spellCheck="false"
                        />
                        <Button
                            className={styles.header__purchase__button}
                            id="buy-button"
                            primary
                            loading={this.state.loadingButton}
                        >
                            {this.state.loadingButton
                                ? (<Button.Content visible>working...</Button.Content>)
                                : (<Button.Content visible>Buy NFT</Button.Content>)
                            }
                            
                        </Button>
                    </div>
                    <div className={styles.header__purchase__row}>
                        {this.state.processingMessage 
                            && <span className={styles.header__purchase__info}>{this.state.processingMessage}</span>
                        }
                    </div>
                    <div className={styles.header__purchase__row}>
                        <span id="error-nft-input" className={styles.header__purchase__error}/>
                        {this.state.errorMessage 
                            && <span className={styles.header__purchase__error}>{this.state.errorMessage}</span>
                        }
                    </div>
                </Form>
            </div>
        );
    }
}

export default PurchaseTokens;
