import styles from "./styles.module.scss";
import { Container } from "../Container/Container";
import spinSnail from "../../assets/images/spinSnail.png";
import redStars from "../../assets/images/redStars.png";

export const About = ({ name }) => {
    return (
        <div className={styles.about} name={name} id="joinSnails">
            <div className={styles.about__starsImage}>
                <img
                    className={styles.about__starsImage__redstars}
                    src={redStars}
                    alt="red stars"
                />
            </div>
            <Container>
                <div className={styles.about__content}>
                    <div className={styles.about__content__item}>
                        <img
                            className={styles.about__img}
                            src={spinSnail}
                            alt="spinSnail"
                        />
                        {/* <button
                            className={`${styles.about__link} ${styles.about__link__yellow}`}
                        >
                            spin the snail
                        </button> */}
                        <a className={styles.about__link} href="#nft-input">Mint on Ethereum</a>
                    </div>
                    <div className={styles.about__content__item}>
                        <h2 className={styles.about__title}>How to get in</h2>
                        <p className={styles.about__subtitle}>StarSnail NFT will be your exclusive ticket to the community with:</p>
                        <ul>
                            <li>
                                <b>Educational materials</b> and website will be 
                                built on how to start and make your own Metaverse project
                            </li>
                            <li>
                                Hours of <strong>video materials made by crypto</strong> 
                                educators and artists exclusively for the StarSnails community
                            </li>
                            <li>
                                <strong>Help and inspiration</strong> of a supportive community 
                                of people who are interested in making great things in the Metaverse
                            </li>
                            <li>
                                Our <b>personal toolset</b> for launching your NFT and MV project
                            </li>
                            <li>
                                A <b>possibility</b> to get a BIG funding for your next idea
                            </li>
                            <li>
                                <b>Exclusive discord channel</b> for StarSnail
                                holders, whether you are an adult or child we
                                will have channels for you to learn with each
                                other
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>
        </div>
    );
};
