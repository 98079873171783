import styles from "./styles.module.scss";
import { Container } from "../Container/Container";
import videoImg from "../../assets/images/video-play-img.png";
import greenStars from "../../assets/images/greenStars.png";

export const AboutVideo = ({ name }) => {
    // window.onload = function () {
    //     document.getElementById("imageVideo").onclick = function () {
    //         this.style.display = "none";
    //         document.getElementById("playVideo").style.display = "block";
    //     };
    // };

    return (
        <div className={styles.aboutVideo} name={name} id="aboutUs">
            <div className={styles.aboutVideo__starsImage}>
                <img
                    className={styles.aboutVideo__starsImage__greenstars}
                    src={greenStars}
                    alt="green stars"
                />
            </div>
            <Container>
                <div className={styles.aboutVideo__content}>
                    <div className={styles.aboutVideo__content__item}>
                        <h2 className={styles.aboutVideo__title}>Who we are</h2>
                        <p className={styles.aboutVideo__text}>
                            We want to raise funds to invite professional 3D
                            artists, technical experts and mentors to help solve
                            technical and artistic problems many creators have -
                            the stumbling blocks to creating professional
                            quality projects.{" "}
                        </p>
                        <p className={styles.aboutVideo__text}>
                            The community will select two projects to fund the
                            creation and launch to the Metaverse. Those that win
                            grants awarded from the community vote will receive
                            funding and instruction from all willing community
                            members and hired mentors to implement these
                            selected ideas.{" "}
                        </p>
                        <p className={styles.aboutVideo__text}>
                            After the launch of the funded projects, all Star
                            Snail holders will be rewarded with airdrop of the
                            final 3d creations, either in MV or NFT.{" "}
                        </p>
                        {/* <p className={styles.aboutVideo__text}>
                            StarSnails is your secret ticket to the community of
                            young NFT and Metaverse creators. Its goal is to
                            teach young kids to work with the new interconnected
                            world of Metaverse, NFTs and blockchain. We want to
                            raise funds to invite professional 3D artists,
                            technical experts and mentors to help solve
                            instrumental and art problems young authors will
                            definitely have - the stumbling blocks to creating
                            professional quality projects.{" "}
                        </p>
                        <p className={styles.aboutVideo__text}>
                            The community will select two projects to fund their
                            creation and launch to the live Metaverse. Selected
                            authors will receive funding and help from all
                            willing community members and hired mentors to
                            implement these selected ideas.{" "}
                        </p>
                        <p className={styles.aboutVideo__text}>
                            After the launch of the funded projects, the
                            community members will be rewarded with airdrop of
                            the final 3d creations, either in MV or NFT.
                        </p> */}
                    </div>
                    <div className={styles.aboutVideo__content__item}>
                        <div className={styles.aboutVideo__content__video}>
                            {/* <div id={"imageVideo"}>
                                <img src={videoImg} alt={"play"} />
                            </div> */}
                            <div
                                className={
                                    styles.aboutVideo__content__iframe
                                }
                                id={"playVideo"}
                            >
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/Wmc8LPGE0pQ?controls=0"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};
