import styles from "./styles.module.scss";
import headerLogo from "../../assets/images/headerLogo.png";
import { Container } from "../Container/Container";
import { Icon } from "../Icon/Icon";
import { ICONS } from "../../Constants/icons";

export const Footer = () => {
    return (
        <div className={styles.footer}>
            <Container>
                <div className={styles.footer__wrap}>
                    <div className={styles.footer__leftWrap}>
                        <div className={styles.footer__menu}>
                            <ul className={styles.footer__menu__list}>
                                <li className={styles.footer__menu__item}>
                                    <a href="#aboutUs">About us</a>
                                </li>
                                <li className={styles.footer__menu__item}>
                                    <a href="#joinSnails">Join Snails</a>
                                </li>
                                <li className={styles.footer__menu__item}>
                                    <a href="#roadmap">Roadmap</a>
                                </li>
                                <li className={styles.footer__menu__item}>
                                    <a href="#featuredStars">Collections</a>
                                </li>
                                <li className={styles.footer__menu__item}>
                                    <a href="#featuredStars">NFTs</a>
                                </li>
                            </ul>
                        </div>
                        <div className={styles.footer__logo}>
                            <img
                                className={styles.footer__logo__img}
                                src={headerLogo}
                                alt="logo"
                            />
                            <h2 className={styles.footer__logo__text}>
                                Star Snails
                            </h2>
                        </div>
                    </div>
                    <div className={styles.footer__social}>
                        <a
                            href="https://opensea.io/collection/starsnails"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Icon name={ICONS.social4} />
                        </a>
                        <a
                            href="https://twitter.com/SnailsStar"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Icon name={ICONS.twitter} />
                        </a>
                        <a
                            href="https://discord.gg/nNcRqVFeeP"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Icon name={ICONS.discord} />
                        </a>
                    </div>
                    <p className={styles.footer__text}>© 2022 StarSnails NFT</p>
                    {/* <div className={styles.footer__links}>
                    <a className={styles.footer__links__link} href="/">
                        Team
                    </a>
                    <a className={styles.footer__links__link} href="/">
                        Privacy Policy
                    </a>
                    <a className={styles.footer__links__link} href="/">
                        Terms of Use
                    </a>
                    <a className={styles.footer__links__link} href="/">
                        Contact Us
                    </a>
                </div> */}
                </div>
            </Container>
        </div>
    );
};
