export const ICONS = [
    "icon-bgStar1",
    "icon-bgStar2",
    "icon-bgStar3",
    "icon-bgStar4",
    "icon-bgStar5",
    "icon-bgStar6",
    "icon-bgStar7",
    "icon-bgStar8",
];
