import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import pagerImageActive from "../../assets/images/pagerImageActive.png";
import pagerImage from "../../assets/images/pagerImage.png";

let childrenHeightList = [];

export const Pager = ({ children }) => {
    const [activeLink, setActiveLink] = useState("header");
    const pagerRef = useRef(null);

    useEffect(() => {
        const childrenList = pagerRef.current.children;
        const bufArr = [];

        for (let i = 0; i < childrenList.length - 1; i++) {
            const rect = childrenList[i].getBoundingClientRect();
            bufArr.push({
                height: rect.height,
                name: childrenList[i].getAttribute("name"),
            });
        }

        childrenHeightList = bufArr;
    });

    const renderButtons = () => {
        const list = children.filter((item) => item.props.showPager);
        return list.map((child, key) => {
            const name = child.props.name;
            return (
                <button
                    onClick={() => handleClick(name)}
                    className={`${styles.pagerNav__link} ${
                        activeLink === name ? styles.pagerNav__link__active : ""
                    }`}
                    key={key}
                >
                    <span>{key + 1}</span>
                    <img
                        className={styles.pagerNav__link__pagerImage}
                        src={pagerImage}
                        alt=""
                    />
                    <img
                        className={styles.pagerNav__link__pagerImageActive}
                        src={pagerImageActive}
                        alt=""
                    />
                </button>
            );
        });
    };

    const handleClick = (name) => {
        let prevItemHeight = 0;
        childrenHeightList.forEach((item, key) => {
            if (item.name === name) {
                if (key === 0) {
                    pagerRef.current.scrollTo({ top: 0, behavior: "smooth" });
                } else
                    pagerRef.current.scrollTo({
                        top: prevItemHeight,
                        behavior: "smooth",
                    });
            } else prevItemHeight += item.height;
        });
    };

    const handleScroll = (e) => {
        const scrollTop = e.target.scrollTop + childrenHeightList[0].height / 2;
        let elemPos = 0;
        for (let i = 0; i < childrenHeightList.length; i++) {
            const item = childrenHeightList[i];
            if (item.height + elemPos > scrollTop && elemPos < scrollTop) {
                if (activeLink !== item.name) {
                    setActiveLink(item.name);
                }
                break;
            } else elemPos += item.height;
        }
    };

    return (
        <>
            <nav className={styles.pagerNav}>
                <div className={styles.pagerNav__wrapper}>
                    {renderButtons()}
                </div>
                <div className={styles.pagerNav__bgLine}></div>
            </nav>
            <div
                ref={pagerRef}
                className={styles.pager}
                onScroll={handleScroll}
            >
                {children}
            </div>
        </>
    );
};
