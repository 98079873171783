import styles from "./styles.module.scss";
import planetsBg from "../../assets/images/planetsBg.png";
import planetsBgMobile from "../../assets/images/planetsBgMobile.png";
import trailDots from "../../assets/images/trailDots.png";
import redStars from "../../assets/images/redStars.png";
import greenStars from "../../assets/images/greenStars.png";

export const Trail = ({ name }) => {
    return (
        <div className={styles.trail__wrap} name={name} id="roadmap">
            <div className={styles.trail__planetsBg}>
                <img
                    className={styles.trail__planetsBg__img}
                    src={planetsBg}
                    alt="planets background"
                />
                <img
                    className={styles.trail__planetsBg__mobileImg}
                    src={planetsBgMobile}
                    alt="planets background mobile"
                />
            </div>
            <div className={styles.trail} name={name}>
                <h2 className={styles.trail__title}>Roadmap</h2>
                <p className={styles.trail__subtitle}>Follow the snail trail</p>
                <div className={styles.trail__content}>
                    <div className={styles.trail__item}>
                        <div className={styles.trail__item__wrap}>
                            <span className={styles.trail__item__num}>10%</span>
                            <p className={styles.trail__item__text}>
                                Start creation of education materials for NFT
                                and Metaverse creators, for holders. End result
                                will be a standalone website for everyone to use
                            </p>
                        </div>
                    </div>
                    <div className={styles.trail__item}>
                        <div className={styles.trail__item__wrap}>
                            <span className={styles.trail__item__num}>20%</span>
                            <p className={styles.trail__item__text}>
                                Invite metaverse/crypto educators as advisors.
                            </p>
                        </div>
                    </div>
                    <div className={styles.trail__item}>
                        <div className={styles.trail__item__wrap}>
                            <span className={styles.trail__item__num}>30%</span>
                            <p className={styles.trail__item__text}>
                                Do a community vote for which project to
                                create..
                            </p>
                        </div>
                    </div>
                    <div className={styles.trail__item}>
                        <div className={styles.trail__item__wrap}>
                            <span className={styles.trail__item__num}>40%</span>
                            <p className={styles.trail__item__text}>
                                Invite NFT/metaverse educators to Discord to
                                help holders.
                            </p>
                        </div>
                    </div>
                    <div className={styles.trail__item}>
                        <div className={styles.trail__item__wrap}>
                            <span className={styles.trail__item__num}>50%</span>
                            <p className={styles.trail__item__text}>
                                Fund 10% of profits to the first voted project.
                            </p>
                        </div>
                    </div>
                    <div className={styles.trail__item}>
                        <div className={styles.trail__item__wrap}>
                            <span className={styles.trail__item__num}>60%</span>
                            <p className={styles.trail__item__text}>
                                We release all our edu resources in public.
                            </p>
                        </div>
                    </div>
                    <div className={styles.trail__item}>
                        <div className={styles.trail__item__wrap}>
                            <span className={styles.trail__item__num}>80%</span>
                            <p className={styles.trail__item__text}>
                                Do a community vote for the second project to
                                get funding and mentorship.
                            </p>
                        </div>
                    </div>
                    <div className={styles.trail__item}>
                        <div className={styles.trail__item__wrap}>
                            <span className={styles.trail__item__num}>
                                100%
                            </span>
                            <p className={styles.trail__item__text}>
                                Start creation of a standalone website for NFT
                                and Metaverse creators catered to teenagers,
                                utilizing the educational materials,
                                partnerships, and mentorships created from the
                                community discord
                            </p>
                        </div>
                    </div>
                    <div className={styles.trail__dotsBg}>
                        <img
                            className={styles.trail__dotsBg__img}
                            src={trailDots}
                            alt="dots background"
                        />
                    </div>
                    <div className={styles.trail__starsImage}>
                        <img
                            className={styles.trail__starsImage__redstars}
                            src={redStars}
                            alt="red stars"
                        />
                        <img
                            className={styles.trail__starsImage__greenstars}
                            src={greenStars}
                            alt="green stars"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
