import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "./assets/styles/index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fontsource/roboto";

import config from "./config/config";
import TagManager from 'react-gtm-module'
import { utmContentFunc } from "./components/GtmScript/utm";

const tagManagerArgs = {
    gtmId: config.GTM_ID
}
TagManager.initialize(tagManagerArgs)
utmContentFunc();

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
