export const ICONS = {
    twitter: "icon-socialTwitter",
    telegram: "icon-socialTelegram",
    discord: "icon-socialDiscord",
    social1: "icon-social1",
    social2: "icon-social2",
    social3: "icon-social3",
    social4: "icon-social4",
    arrowSlider: "icon-arrowSlider",
};
